<template>
  <div class="flex-1 flex flex-col  max-h-full">
    <sub-header :handleSearchFilter="handleSearchFilter" :showBtn="true"  :handleClick="showCheckModal" buttonText="Create Check" />
    <div class="flex flex-1 flex-col flex-grow max-h-full max-w-full overflow-auto scroll-bar mx-6">
        <dv-table :isLoading="isLoading" :headers="columns" :rows="getFilteredResult" :isClickable="true" :handleRowClick="handleRowClick">
            <template v-slot:items="{ row }">
                <td class="flex-wrap flex gap-3 flex-col py-4 px-3 text-sm">
                    <div class="flex items-center">
                        <div >
                          <div class="font-medium text-gray-800 max-w-xs line-clamp-1">{{row.name}}</div>
                          <p class="text-gray-500 max-w-xs break-words truncate text-xs line-clamp-1"> {{row.description}} </p>
                        </div>
                    </div>
                     <div class="flex flex-wrap gap-1">
                          <span class="text-xs bg-dvbrandhoveron text-gray-50 px-2 py-0.5 rounded-full" v-for="(param, idx) in row.parameters" :key="idx">
                            {{row.name}} - <span class="font-extrabold">{{param.value}}</span> 
                          </span>
                      </div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span class="inline-flex rounded-sm  px-2 text-xs font-semibold leading-5"  :class="row.is_custom ? 'bg-green-100 text-green-800' : 'bg-gray-200 text-gray-800 hover-text' " >{{row.is_custom? 'Custom': 'locked'}} 
                      <span class="tooltip-text" id="right">This is system provided check, <br /> can not be edited directly. <br /> To make modification you need to clone this check</span> 
                    </span>



                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    used in <span class="text-gray-700 font-bold">{{row.packaged_included || 0}} </span>packages
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{row.sla ? row.sla : '0'}}
                  </td>
                  


                  <td class="whitespace-nowrap h-full px-3  py-4 text-sm text-gray-500">
                    <div class="flex  justify-between items-center m-0">
                        {{row.is_automatic ? 'Automatic': 'Manual'}}
                        <div class="flex gap-3 items-center">
                            <span @click.stop="handleCloneCheck(row)" class="h-8 items-center justify-center flex rounded-half w-8 bg-gray-200 hover:bg-gray-300">
                                <font-awesome-icon icon="clone" class=" text-gray-500  ease-in-out transition-all duration-100 w-24 text-md self-center cursor-pointer" />
                            </span>
                            <font-awesome-icon icon="angle-right" class="text-gray-500 w-32 text-xl self-center cursor-pointer hover:opacity-70" />
                        </div> 
                    </div>
                
                </td>
                  <!-- <td class="py-4 px-2 text-center text-xl font-medium">
                        <font-awesome-icon icon="angle-right" class=" text-brand w-32 text-2xl self-center cursor-pointer hover:opacity-70" />
                  </td> -->
            </template>
            <template v-slot:no-data>
              <span class="w-full flex items-center justify-center p-3">No data Found</span>
            </template>
        </dv-table>

    </div>

<!-- Create Check Modal -->
    <modal-content
      ref="check-modal"
      :max-width="480"
      name="check-modal"
      title="Add New Check"
      @close="closeCheckModal"
    >
      <template #content>
        <div class="flex-1 " >
          <div class="flex flex-col mb-4">
            <span class="text-gray-6 inline-block py-2"
              >Check Name</span
            >
              <Input
                placeholder="Check Name"
                bg="white"
                v-model="checkData.check_name"
             />
          </div>
          <div class="flex flex-col mb-4">
            <span class="text-gray-6 inline-block py-2"
              >Check Description</span
            >
              <textarea
                class="border border-gray-200 border-solid rounded-md max-h-44 text-sm p-2"
                placeholder="Check Description"
                bg="white"
                v-model="checkData.description"
              />
          </div>
        </div>
      </template>
      <template #footer>
        <div class="flex w-full justify-end">
          <Button
                :disabled="createCheckLoading || !checkData.description || !checkData.check_name"
                @click="handleCreateCheck"
                class="btn-primary"
                text="Create Check "
            />
          
        </div>
      </template>
    </modal-content>
<!-- End Create Check Modal -->

  </div>
</template>
<script>
import SubHeader from "@/components/SubHeader";
import dvTable from  "@/components/devliumComponent/field-table/dv-table"
import axios from "@/axios";
import { debounce } from "lodash";
import modalContent from "@/components/modal-content";
import Input from "@/components/input";
import Button from "@/components/button";

export default {
  name: "check-admin",
  components: {
      SubHeader,
      dvTable,
      modalContent,
      Input,
      Button
  },
  props: {},
  data: () => ({
        columns: [
          {"label":"Name","field":"field_info"},
          {"label":"Source","field":"field_type_status"},
          {"label":"Packages","field":"packaged_included"},
          {"label":"SLA","field":"sla"},
          {"label":"Automatic/Manual","field":"is_automatic"},
        
        ],
        checks: [],
        isLoading: false,
        isCloning: false,
        searchValue: "" ,
        createCheckLoading: false,
        checkData: {
          check_name: "",
          description: "",
          sla: 1
        }
  }),

  created() {
    
  },
  computed:{
    getFilteredResult() {
      let searchValue = this.searchValue;
      if (searchValue) {
        let newRow = this.checks.filter((ele) =>
          ele.name.toLowerCase().includes(searchValue.toLowerCase())
        );
        return newRow;
      } else {
        return this.checks;
      }
    },
  },

  async mounted() {
    if (!this.$store.getters.getTenantId) {
			await this.$store.dispatch('fetchTenantId')
		}
    await this.fetchChecksList()
  },

  methods: {
    closeCheckModal(){
      this.$refs["check-modal"].hideModal();
    },

    showCheckModal() {
        this.checkData =  {
          check_name: "",
          description: "",
          sla: 1
        }
        this.$refs["check-modal"].showModal();
    },
    // get checks list
    async fetchChecksList(){
        this.isLoading = true;
        const tenant = this.$store.getters.getTenantId
        let url = `/tenant-check/${tenant}`;
        let {data} = await axios.get(url);
        try{
          let checksData=  data?.data?.map(el => {
            if(el.parameters){
              let data = JSON.parse(el.parameters)
              return {...el, parameters:data.parameters}
            }else{
              return {...el}
            }
          })
          this.checks = checksData
        }
        catch(error){
          console.log(error, "Error")
        }
        // this.columns = columns;
        this.isLoading = false;
    },
    // end checks list 

    handleRowClick(data){
        this.$router.push({
            name: "check View",
            params: { id: data.id },
        });
    },

    // Create Check 

    async handleCreateCheck(){
        this.createCheckLoading = true
        let url = `/tenant-check/custom-check`;
        let payload = this.checkData
        payload = {...payload, tenant_id:this.$store.getters.getTenantId}
        try{
            let {data} = await axios.post(url, payload);
            let checkData = {
                description: payload.description,
                id: data.check_id.id,
                is_custom: true,
                name: payload.check_name,
                parameters:null,
                sla:payload.sla,
                tenant_id: this.$store.getters.getTenantId
              
            }
            this.checks.push(checkData)
            this.$toast.success(data.message);
            this.closeCheckModal()
        }
        catch(error){
            this.createCheckLoading = false
            this.$toast.error(error.response.data.detail || 'Failed to create new check');
        }
        this.createCheckLoading = false
        
    },

    // End Create Check

    // clone check 

    async handleCloneCheck(check){
        // this.isCloning = true
        let url = `/tenant-check/${check.id}/clone`;
        let payload = {
          tenant_id: this.$store.getters.getTenantId,
          check_name: check.name
        }
        try{
          let {data} = await axios.post(url, payload);
          this.$router.push({
                name: "check admin details",
                params: { id: data.check_id.id},
          });
          this.$toast.success(data.message);
        }catch(error){
          this.$toast.error("Unable to clone check admin");
        }
    },
    handleSearchFilter: debounce(function (value) {
      this.searchValue = value;
    }, 1000),
    
    // end Clone Check
  },
};
</script>

<style lang="scss">
.tooltip-text {
  display: none;
  position: absolute;
  z-index: 999 !important;
  width: auto;
  color: white;
  font-size: 12px;
  background-color: #192733;
  border-radius: 10px;
  padding: 10px 15px 10px 15px;
}



.hover-text:hover .tooltip-text {
  display: block;
}

#right {
  left: 70px;
  top: -30px
}



// #bottom {
//   top: 25px;
//   left: -50%;
// }

// #bottom::before {
//   top: -5%;
//   left: 45%;
// }

// #left {
//   top: -8px;
//   right: 120%;
// }

// #left::before {
//   top: 35%;
//   left: 94%;
// }

// #right {
//   top: -8px;
//   left: 120%;
// }

// #right::before {
//   top: 35%;
//   left: -2%;
// }

.hover-text {
  position: relative;
}

</style>
